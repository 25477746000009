<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("schoolTimeTable.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="newItem()">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("schoolTimeTable.list.name") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.shift") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ shiftVal(item.shift) }}</td>
                <td>
                  <b-button
                      type="submit"
                      @click="editGroup(item)"
                      variant="primary"
                  >
                    {{ $t("common.edit") }}
                  </b-button>
                  <router-link :to="{ name: 'schoolTimeTableDetails', params: { id: item.id }}" class="btn btn-icon btn-success">
                    <i class="flaticon2-arrow"></i>
                  </router-link>
                  <b-button
                      type="submit"
                      @click="deleteTt(item)"
                      variant="danger"
                      style="margin-left: 10px"
                  >
                    {{ $t("common.delete") }}
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <b-modal id="schoolTimeTable-modal" ref="schoolTimeTable-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schoolTimeTable.list.name") }}
                    </template>
                    <b-form-input
                        v-model="form.name"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schoolTimeTable.list.shift") }}
                    </template>
                    <b-form-select
                        v-model="form.shift"
                        :options="shifts"
                    ></b-form-select>
                  </b-form-group>
                  
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "SchoolTimeTable",
  components: {
    KTPortlet
  },
  data() {
    return {
      schoolId: null,
      list: null,
      form: {
        name: "",
        shift: "",
      },
      shifts: [{text: "Первая", value: 1}, {text: "Вторая", value: 2}, { text: "Третья", value: 3 }],
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.schoolId = this.$route.params.id;

      ApiService.postSecured("schoolTimeTable/save", this.form)
          .then(function () {
            $this.$refs["schoolTimeTable-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["schoolTimeTable-modal"].hide();
    },
    reloadData() {
      let $this = this;
      ApiService.querySecured("schoolTimeTable/list", {}).then(({data}) => {
        $this.list = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    newItem() {
      this.form = {};
      this.$refs["schoolTimeTable-modal"].show();
    },
    editGroup(group) {
      this.form = group;
      this.$refs["schoolTimeTable-modal"].show();
    },
    deleteTt(timeTable) {
      if(confirm("Удалить?")){
        let $this = this;
        ApiService.postSecured("schoolTimeTable/delete", {id:timeTable.id})
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response);
              $this.reloadData();
            });
      }      
    },
    shiftVal(shift) {
      let shiftVal = '';
      switch (shift) {
        case 1:
          shiftVal = 'Первая';
          break;
        case 2:
          shiftVal = 'Вторая';
          break;
        case 3:
          shiftVal = 'Третья';
          break;
      }

      return shiftVal;
    }
  }
};
</script>
